import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import { ValidateCI } from '../utils/validateCi'
import MaskedInput from "react-text-mask";



import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik'



const Solicitud = (props) => {

  const showCrediton = false;
  const assets = 'assets'

  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };

  //Setters
  const [loading, setLoading] = React.useState(false);
  const [showToast, setToast] = React.useState(false);
  const [blurredDate, setBlurredDate] = React.useState(false);
  const [mesValue, setMes] = React.useState();
  const [diaValue, setDia] = React.useState();
  const [anoValue, setAno] = React.useState();
  const [activeStep, setActiveStep] = React.useState(1);
  const [isCrediton, setIsCrediton] = React.useState(false);
  const [isCreditonPlus, setIsCreditonPlus] = React.useState(false);


  const [hasMounted, setHasMounted] = React.useState(false);


  //First step
  const [mensaje, setMensaje] = React.useState();
  const [title, setTitle] = React.useState();
  const [wpp, setWpp] = React.useState();

  //Form
  const campoError = 'El campo es requerido'
  var noValidate = false;
  var errors = {};

  //Date validation
  const getDia = (evt) => {

    setDia(evt.target.value)
    setBlurredDate(true);

  }
  const getAno = (evt) => {

    setAno(evt.target.value);
    setBlurredDate(true);

  }
  const getMes = (evt) => {

    setMes(evt.target.value)
    setBlurredDate(true);

  }


  const maxLengthCheck = (object) => {

    if (object.target.value.length == object.target.maxLength) {

      if (object.target.nextSibling) {
        object.target.nextSibling.focus()
      }

    }

    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }

  }

  //CI

  const CIMask = [
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/
  ];


  //Tel

  const TelMask = [
    /[0]/,
    /[9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/
  ];



  useEffect(() => {
    setHasMounted(true);
  }, []);




  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-solicitud'} >


        <SEO metadata={$metadata} title={'Solicitar préstamo'}></SEO>


        <>

          <Helmet>


            <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=11`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=2`)} rel="stylesheet" type="text/css" />

            <link href={withPrefix(`../../${assets}/css/inline/form-inline.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/solicitud.css?v=4`)} rel="stylesheet" type="text/css" />


          </Helmet>


          {/* HERO */}
          <div className="c-form-hero">
            <div className="c-form-hero__image">
            </div>{/* .c-hero__holder */}
          </div>{/* .c-hero */}



          <div className="c-viewport o-wrapper">{/*
                    */}{showToast && !loading &&
              <div className="c-toast is-active" onClick={() => { setToast(false) }}>
                <div className="c-toast_holder">
                  <span className="c-toast__icon" />
                  <button className="c-toast__close" onClick={() => { setToast(false) }} />
                  <span className="c-toast__alert"><strong>Error:</strong></span>
                  <span className="c-toast__text">Ha ocurrido un error al enviar el formulario, intente nuevamente.</span>
                </div>
              </div>}{/*
                    */}{loading &&
              <div className="c-viewport__step c-loading">
                <span className="c-loading-animation c-loading-animation--large" />
                <span className="c-loading-text">Cargando...</span>
              </div>}{/*
                    */}{activeStep == 1 && !loading &&
              <div className="c-viewport__step o-section">
                <div className="c-viewport__step-holder">

                  <h2 className={`c-title c-title--small ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>Solicitá tu préstamo</h2>
                  <span className={`c-viewport__subtitle c-text ${showCrediton ? 'c-title--red' : 'c-title--blue'}`}>Completá tus datos para iniciar tu solicitud.</span>


                  <Formik

                    validateOnChange={false}

                    initialValues={{
                      identity_doc: '',
                      cellphone: '',
                      email: '',
                      clearing: '',
                      birthday: '-',
                      payment_freq: typeof window !== 'undefined' && localStorage.getItem('solicitud-cuotas') != null ? localStorage.getItem('solicitud-cuotas') : 3,
                      total: typeof window !== 'undefined' && localStorage.getItem('solicitud-total') != null ? localStorage.getItem('solicitud-total') : '',
                      deluxe: showCrediton ? 'false' : 'true',
                      gclid: 'xxxxxxxxxx',
                      conditions: true,
                      conditions2: true,
                      utm_source: 'xxxxxxxxxx',
                      utm_medium: 'xxxxxxxxxx',
                      utm_campaign: 'xxxxxxxxxx',
                      utm_term: 'xxxxxxxxxx',
                      utm_content: 'xxxxxxxxxx',
                      utm_tracking: 'xxxxxxxxxx',
                    }}

                    onSubmit={
                      (values, actions) => {

                        setLoading(true);

                        //Clean values
                        delete values.conditions;
                        delete values.conditions2;
                        values.identity_doc = values.identity_doc.replace(/\D/g, '');
                        values.cellphone = values.cellphone.replaceAll(' ', '');
                        values.cellphone = values.cellphone.trim();
                        values.email = values.email.replaceAll(' ', '');
                        values.email = values.email.trim();

                        localStorage.getItem('gclid') != null ? values.gclid = localStorage.getItem('gclid') : values.gclid = '';

                        localStorage.getItem('utm_source') != null ? values.utm_source = localStorage.getItem('utm_source') : values.utm_source = '';
                        localStorage.getItem('utm_medium') != null ? values.utm_medium = localStorage.getItem('utm_medium') : values.utm_medium = '';
                        localStorage.getItem('utm_campaign') != null ? values.utm_campaign = localStorage.getItem('utm_campaign') : values.utm_campaign = '';
                        localStorage.getItem('utm_term') != null ? values.utm_term = localStorage.getItem('utm_term') : values.utm_term = '';
                        localStorage.getItem('utm_content') != null ? values.utm_content = localStorage.getItem('utm_content') : values.utm_content = '';
                        localStorage.getItem('utm_tracking') != null ? values.utm_tracking = localStorage.getItem('utm_tracking') : values.utm_tracking = '';
                       
                        //Fetch to API
                        (async () => {

                          const rawResponse = await fetch(`https://becredweb.volmet.com.uy/api/v1/loan_simulations`, {
                            method: "POST",
                            headers: {
                              'Accept': "application/json",
                              'Content-Type': "application/json",
                              'Authorization': 'Token token=fc785cfb17ba4c45b6b1'
                            }, withCredentials: true,
                            body: JSON.stringify({ ...values })
                          })

                          //Get API response
                          const response = await rawResponse.json();


                          if (response.status == "OK") {

                            //Patch response data
                            setTitle(response.title);
                            setWpp(response.show_whatsapp);
                            setMensaje(response.message);

                            setActiveStep(2);
                            setLoading(false);

                            //Pixel FB convertion
                            window.fbq('track', 'Solicitud', values);


                          } else {

                            setLoading(false);
                            setToast(true);

                            setTimeout(() => {
                              setToast(false);
                            }, 5000);

                          }




                        })();

                      }
                    }
                    validate={values => {

                      noValidate = true;

                      errors = {};

                      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


                      //Required
                      Object.keys(values).
                        forEach((id) => {
                          if ((!values[id] || values[id] == "null") && id != "email") {
                            errors[id] = campoError
                          }
                        })

                      //CI
                      if (values.identity_doc && (!ValidateCI(values.identity_doc) || values.identity_doc.length < 7)) {
                        errors.identity_doc = "La cédula no es válida"
                      }


                      //Birthday
                      if (!mesValue || !anoValue || !diaValue) {
                        errors.birthday = "La fecha no es válida"
                      }

                      if (mesValue && anoValue && diaValue) {

                        if (mesValue <= 12 && mesValue > 0 && diaValue <= 31 && diaValue > 0 && anoValue > 0 && anoValue.length == 4) {
                          values.birthday = diaValue + "/" + mesValue + "/" + anoValue
                        } else {
                          errors.birthday = "La fecha no es válida"
                        }

                      }

                      //Cellphone
                      if (values.cellphone && (values.cellphone.length < 9 || !/^\d+$/.test(values.cellphone.replaceAll(' ', '')))) {
                        errors.cellphone = "El celular no es válido"
                      }

                      //Email      
                      if (values.email && !emailRegex.test(values.email)) {
                        errors.email = "El email no es válido"
                      }

                      //Amount
                      if (!showCrediton) {
                        if (values.total && values.total < 50000) {
                          errors.total = "El valor debe ser mayor a $50.000"
                        }
                      }
                      if (values.total && values.total > 150000) {
                        errors.total = "El valor debe ser menor a $150.000"
                      }

                      if (!showCrediton) {
                        if (values.total && values.total < 50000) {
                          setIsCrediton(true);

                        } else {
                          setIsCrediton(false)
                        }
                      } else {
                        if (values.total && values.total >= 50000) {
                          setIsCreditonPlus(true)
                        } else {
                          setIsCreditonPlus(false)
                        }
                      }

                      //Round amount
                      if (values.total && values.total % 100 !== 0) {
                        values.total = Math.ceil(values.total / 100) * 100;
                      }

                      return errors;


                    }}
                  >
                    {({ validateForm, setFieldValue, values, handleChange, handleBlur }) => (

                      <Form key="solicitud-form" name="solicitud-form" method="POST" className="c-form">


                        <div className="c-form__group">

                          <ErrorMessage name="total" children={() => { return (<label htmlFor="total" className="c-form__error">{errors.total}</label>) }} />

                          <label className="c-form__label">
                            <span className="c-form__label-text">Monto a solicitar</span>
                            <span className="c-form__money">
                              <Field name="total" type="number" inputmode="decimal" onBlur={(e) => { handleBlur(e); validateForm() }} className="c-form__input" min="4000" max="150000" step="100" placeholder={showCrediton ? "10000" : "50000"} onClick={e => e.target.select()}
                              />
                            </span>
                          </label>

                        </div>


                        {((!isCrediton && !showCrediton) || (!isCreditonPlus && showCrediton)) &&

                          <>

                            <div className={`c-form__group`}>

                              <ErrorMessage name="payment_freq" children={() => { return (<label htmlFor="payment_freq" className="c-form__error">{errors.payment_freq}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Cuotas</span>

                                <div className="c-form__select">

                                  <Field name="payment_freq" as="select" className={`c-form__input`}>
                                    <option disabled value="null">Selecciona cantidad de cuotas...</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="9">9</option>
                                    <option value="12">12</option>
                                    <option value="15">15</option>
                                    <option value="18">18</option>
                                    <option value="24">24</option>
                                    <option value="30">30</option>
                                    <option value="36">36</option>
                                  </Field>

                                </div>

                              </label>

                            </div>



                            <div className="c-form__group">

                              <ErrorMessage name="identity_doc" children={() => { return (<label htmlFor="identity_doc" className="c-form__error">{errors.identity_doc}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Cédula de Identidad</span>


                                <Field
                                  name="identity_doc"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={CIMask}
                                      placeholder="0.000.000-0"
                                      type="tel"
                                      className="c-form__input"
                                    />
                                  )}
                                />




                              </label>


                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="email" children={() => { return (<label htmlFor="email" className="c-form__error">{errors.email}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Email</span>
                                <Field name="email" type="email" className="c-form__input" placeholder="juan.lopez@crediton.com.uy" />
                              </label>

                            </div>


                            <div className="c-form__group">

                              <ErrorMessage name="birthday" children={() => { return (<label htmlFor="birthday" className="c-form__error">{errors.birthday}</label>) }} />
                              {errors.birthday && blurredDate && <label htmlFor="birthday" className="c-form__error">{errors.birthday}</label>}


                              <div className="c-form-birthday">


                                <label htmlFor="day" className="c-form__label-text c-form__label">Fecha de Nacimiento</label>
                                <input id="day" inputmode="decimal" min="1" max="31" type="number" className="c-form__input c-form__input-birthday" placeholder="DD" onFocus={getDia} onChange={getDia} maxLength="2" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />{/*
                                            */}<input min="1" max="12" inputmode="decimal" type="number" className="c-form__input c-form__input-birthday" placeholder="MM" onFocus={getMes} onChange={getMes} maxLength="2" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />{/*
                                            */}<input min="1900" type="number" inputmode="decimal" className="c-form__input c-form__input-birthday" placeholder="AAAA" onFocus={getAno} onChange={getAno} maxLength="4" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />

                              </div>{/* .c-form-birthday */}
                            </div>



                            <div className="c-form__group">

                              <ErrorMessage name="clearing" children={() => { return (<label htmlFor="clearing" className="c-form__error">{errors.clearing}</label>) }} />

                              <div className="c-form__clearing">
                                <span className="c-form__label-text  c-form__label">¿Estás en el clearing?</span>


                                <label className="c-form__label-clearing">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing " value="yes" />

                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">Si</span>

                                </label>
                                <label className="c-form__label-clearing">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing " value="no" />
                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">No</span>
                                </label>
                                <label className="c-form__label-clearing">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing " value="dont_know" />
                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">No lo sé</span>
                                </label>
                              </div>{/* .c-form__clearing */}


                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="cellphone" children={() => { return (<label htmlFor="cellphone" className="c-form__error">{errors.cellphone}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Celular</span>

                                <Field
                                  name="cellphone"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={TelMask}
                                      placeholder="099 999 999"
                                      type="tel"
                                      className="c-form__input"
                                    />
                                  )}
                                />

                              </label>


                            </div>

                            <label className="c-form__label c-form__label--relative">

                              <ErrorMessage name="conditions" children={() => { return (<label htmlFor="conditions" className="c-form__error">{errors.conditions}</label>) }} />

                              <Field name="conditions" type="checkbox" className="c-form__input-conditions" />

                              <span className="c-checkbox__icon" />
                              <span className="c-checkbox__text">Acepto los <strong><a href={`/terminos-y-condiciones`} target="_blank" className="c-form__label-link">Términos y condiciones</a></strong>, la Asistencia Legal y la
                               <strong><a href={`/facturacion-electronica`} target="_blank" className="c-form__label-link"> Facturación Electrónica</a></strong>.</span>

                            </label>

                            <label className="c-form__label c-form__label--relative">

                              <ErrorMessage name="conditions2" children={() => { return (<label htmlFor="conditions2" className="c-form__error">{errors.conditions2}</label>) }} />

                              <Field name="conditions2" type="checkbox" className="c-form__input-conditions" />

                              <span className="c-checkbox__icon" />
                              <span className="c-checkbox__text">Acepto gestionar la atención al cliente vía<a className="c-form__label-link c-form__label-link--wsp js-whatsapp-btn" href={`${showCrediton ? 'tel:+598091200055' : 'tel:+598099270679'}`}>WhatsApp</a>.</span>

                            </label>

                            <button type="submit" className={`c-btn c-form__btn ${loading ? 'is-loading' : ''} `}> {loading && <span className="c-loading-animation" />}¡Lo quiero ya!</button>
                          </>

                        }

                        {isCrediton}

                        {!showCrediton && isCrediton &&
                          <div className="c-form__submit">
                            <div className="c-form__submit-crediton">
                              <p className="c-form__crediton">Por préstamos menores a $50.000</p>
                              <a href={`https://www.crediton.com.uy/?cbk=${values.total}${localStorage.getItem('gclid') != null ? '&gclid=' + localStorage.getItem('gclid') : ''}`} target="_blank" className="c-btn c-form__btn c-send__btn js-send-form">Visitá<span className="c-btn__logo" /></a>
                            </div>
                          </div>
                        }

                        {showCrediton && isCreditonPlus &&

                          <div className="c-form__submit-plus">
                            <p className="c-form__plus">Por préstamos mayores a $50.000</p>
                            <a href={`https://www.creditonplus.com.uy/?cbk=${values.total}-${values.payment_freq}${localStorage.getItem('gclid') != null ? '&gclid=' + localStorage.getItem('gclid') : ''}`} target="_blank" className="c-btn c-form__btn c-form__btn--plus c-send__btn js-send-form">Visitá<span className="c-btn__logo" /></a>
                          </div>


                        }

                      </Form>

                    )}

                  </Formik>
                </div>
              </div>
            }

            {activeStep == 2 && !loading &&

              <>

                <div className="c-viewport__step c-viewport__step--centred o-section">

                  {title ? <h2 className={`c-title c-title--small c-title--blue c-second-title ${showCrediton && 'c-title--red'} `}>{title}</h2> :
                    <h2 className={`c-title c-title--small c-title--blue c-second-title ${showCrediton && 'c-title--red'} `}>Gracias por tu solicitud!</h2>}
                  {mensaje && <span className="c-form__text c-text c-message-text">{mensaje}</span>}

                  {wpp &&
                    <a href={`${showCrediton ? 'https://wa.me/59891200055' : 'https://wa.me/59899270679'}`} target="_blank" className="c-btn c-form__btn c-send__btn c-wsp__btn"><span className={`${showCrediton ? 'c-btn__icon' : 'c-btn--icon'} icon-wsp`} />Ir a WhatsApp</a>}


                  <Link to={`/`} className="c-form__link c-text" >Volver al inicio</Link>

                </div>

              </>
            }

          </div>





        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query SolicitudQuery {
            prismic {
          allMetadatas  {
            edges {
          node {
            ...MetadataFragment
          }
          }
        },
    allFooters {
            edges {
          node {
            ...FooterFragment
          }
          }
        },
    allInformacion_financieras {
            edges {
          node {
            ...InformacionFinancieraFragment
          }
          }
        } 
      }
    }
    `


export default Solicitud;

